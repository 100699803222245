import { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormHelperText
} from '@mui/material';

/**
 * 文件上傳組件，允許用戶上傳文件並處理文件數據
 * @param {function} setCoordinates - 更新坐標數據的函式
 * @param {function} setCityMarkers - 更新城市標記數據的函式
 * @returns {JSX.Element} 文件上傳組件
 */
const FileUploader = ({ setCoordinates, setCityMarkers }) => {
  const [file, setFile] = useState(null);  // 儲存當前選擇的文件
  const [error, setError] = useState('');  // 儲存錯誤訊息

  /**
   * 處理文件選擇變更
   * @param {Event} e - 文件選擇事件
   */
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);  // 更新文件狀態
    setError('');  // 清除錯誤訊息
  };

  /**
   * 處理文件上傳操作，通過API將文件傳送到服務器
   */
  const handleFileUpload = async () => {
    if (!file) {
      alert("請選擇一個文件");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://sonnyy.philipivy.com/api/upload', formData);
      alert('文件上傳成功');

      // 上傳成功後，更新坐標和城市標記數據
      setCoordinates(response.data.coordinates);  // 更新坐標數據
      setCityMarkers(response.data.city_markers);  // 更新城市標記數據
    } catch (error) {
      console.error('上傳文件出錯', error);
      alert('文件上傳失敗');
    }
  };

  return (
    <Box mt={2} display="flex" flexDirection="column" alignItems="center" gap={2}>
      <FormControl error={!!error}>
        {/* 文件選擇輸入框 */}
        <Input type="file" onChange={handleFileChange} />
        {error && <FormHelperText>{error}</FormHelperText>}  {/* 顯示錯誤訊息 */}
      </FormControl>

      {/* 上傳文件按鈕 */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleFileUpload}
      >
        上傳文件
      </Button>
    </Box>
  );
};

export default FileUploader;
