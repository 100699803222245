import React from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Typography } from '@mui/material';

/**
 * 地圖組件，負責顯示所有坐標上的標記點。
 * @param {Array} coordinates - 道路瑕疵的坐標及其類型。
 */

const MapComponent = ({ coordinates }) => {
  // 使用Google地圖API，初始化地圖腳本
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCv8S7_mK-ioh3VWqXfYq0vz0Vdv4oQhAo',
  });

  if (!isLoaded) return <Typography>載入中...</Typography>; // 若API尚未載入，顯示“載入中...”

  return (
    <GoogleMap
      center={{ lat: 24.1980985, lng: 120.67604075 }} // 設定地圖中心點
      zoom={15.5} // 設定縮放等級
      mapContainerStyle={{ height: '500px', width: '100%' }} // 設置地圖容器大小
    >
      {/* 顯示每個坐標的標記 */}
      {coordinates.map((coord, index) => (
        <Marker
          key={index}
          position={{ lat: coord.latitude, lng: coord.longitude }}
          title={`瑕疵類型: ${coord.defect}`} // 標記的提示
        />
      ))}
    </GoogleMap>
  );
};

export default MapComponent;
