import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';

/**
 * 城市統計表組件，顯示每個城市對應的標記坐標數
 * @param {object} cityMarkers - 包含城市名稱和對應標記坐標數的物件
 * @returns {JSX.Element} 統計表
 */
const StatsTable = ({ cityMarkers }) => (
  <div style={{ marginTop: '20px' }}>
    <Typography variant="h5" gutterBottom>
      城市統計
    </Typography>

    {/* 表格容器，顯示城市名稱及其標記的坐標數 */}
    <TableContainer component={Paper} style={{ maxWidth: 600, margin: '0 auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            {/* 定義表頭：城市名稱與標記坐標數 */}
            <TableCell align="center"><b>城市</b></TableCell>
            <TableCell align="center"><b>標記坐標數</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* 迭代 cityMarkers 物件，為每個城市顯示對應的標記數 */}
          {Object.keys(cityMarkers).map((city, index) => (
            <TableRow key={index}>
              {/* 顯示城市名稱 */}
              <TableCell align="center">{city}</TableCell>
              {/* 顯示對應的標記坐標數 */}
              <TableCell align="center">{cityMarkers[city]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);

export default StatsTable;
