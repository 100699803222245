import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

/**
 * 將時間戳格式化為易讀的格式
 * 例如：將 "2022_10_16_14_30_45" 轉換為 "2022-10-16 14:30:45"
 * @param {string} timestamp - 原始的時間戳
 * @returns {string} 格式化後的時間戳
 */
const formatTimestamp = (timestamp) => {
  return timestamp.replace(/_/g, '-').replace(/-(\d{2})-(\d{2})-(\d{2})$/, ' $1:$2:$3');
};

/**
 * 瑕疵資料表組件，顯示所有的坐標資料並且允許使用者篩選瑕疵類別
 * @param {Array} coordinates - 含有瑕疵資料的坐標數據
 * @param {string} selectedDefect - 當前選擇的瑕疵類別
 * @param {function} handleDefectChange - 處理瑕疵類別變更的函式
 * @returns {JSX.Element} 瑕疵資料表組件
 */
const DefectTable = ({ coordinates, selectedDefect, handleDefectChange }) => {
  return (
    <Box mt={4}>
      <Typography variant="h5" align="center" gutterBottom>
        瑕疵資料表
      </Typography>

      {/* 下拉選單與表格佈局 */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel id="defect-select-label">選擇瑕疵類別</InputLabel>
          <Select
            labelId="defect-select-label"
            value={selectedDefect}
            onChange={handleDefectChange}
            label="選擇瑕疵類別"
          >
            {/* 提供多個瑕疵類別供使用者選擇 */}
            <MenuItem value="所有類別">所有類別</MenuItem>
            <MenuItem value="longitudinal cracks">longitudinal cracks</MenuItem>
            <MenuItem value="transverse cracks">transverse cracks</MenuItem>
            <MenuItem value="alligator cracks">alligator cracks</MenuItem>
            <MenuItem value="potholes">potholes</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* 表格容器，顯示瑕疵坐標數據 */}
      <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {/* 表頭定義：包含緯度、經度與時間戳 */}
              <TableCell align="center"><b>Latitude</b></TableCell>
              <TableCell align="center"><b>Longitude</b></TableCell>
              <TableCell align="center"><b>Timestamp</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* 將每一筆坐標資料顯示在表格中 */}
            {coordinates.map((coord, index) => (
              <TableRow key={index}>
                {/* 顯示緯度，經緯度保留6位小數 */}
                <TableCell align="center">{coord.latitude.toFixed(6)}</TableCell>
                {/* 顯示經度 */}
                <TableCell align="center">{coord.longitude.toFixed(6)}</TableCell>
                {/* 顯示時間戳，格式化後的時間 */}
                <TableCell align="center">{formatTimestamp(coord.time_temp)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DefectTable;
