import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MapComponent from './MapComponent';
import FileUploader from "./FileUploader";
import DefectTable from "./DefectTable";
import StatsTable from "./StatsTable";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  Paper
} from '@mui/material';


// 主應用程式
const App = () => {
  const [coordinates, setCoordinates] = useState([]); // 道路瑕疵坐標
  const [cityMarkers, setCityMarkers] = useState({}); // 城市標記統計
  const [selectedDefect, setSelectedDefect] = useState('所有類別'); // 當前選擇的瑕疵類別
  const [filteredCoordinates, setFilteredCoordinates] = useState([]);

  useEffect(() => {
    // 初始化時從後端獲取數據
    axios.get('https://sonnyy.philipivy.com/api/defect')
      .then(response => {
        setCoordinates(response.data.coordinates); // 設置初始的瑕疵坐標
        setCityMarkers(response.data.city_markers); // 設置城市標記統計
        setFilteredCoordinates(response.data.coordinates); // 初始化篩選後的坐標為所有類別
      })
      .catch(error => console.error('初始化獲取數據時出錯', error));
  }, []);

  // 處理瑕疵類別的篩選變更
  const handleDefectChange = (event) => {
    const defect = event.target.value;
    setSelectedDefect(defect);

    if (defect === '所有類別') {
      setFilteredCoordinates(coordinates);
    } else {
      const filtered = coordinates.filter(coord => coord.defect === defect);
      setFilteredCoordinates(filtered);
    }
  };

  // 處理瑕疵類別選擇的變更

  return (
    <Container maxWidth="lg" sx={{ mt: 4, pb: 4}}>
      <Typography variant="h4" gutterBottom align="center">
        道路瑕疵檢測系統
      </Typography>

      <Grid container spacing={3}>
        {/* 地圖區塊 */}
        <Grid item xs={12} md={8}>
          <Paper elevation={3}>
            <MapComponent coordinates={coordinates} />
          </Paper>
        </Grid>

        {/* 控制區塊 */}
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              Refresh Page
            </Button>

            <FileUploader
              setCoordinates={setCoordinates}
              setCityMarkers={setCityMarkers}
            />
          </Box>
        </Grid>
      </Grid>

      {/* 統計表與瑕疵資料表 */}
      <Box mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <StatsTable cityMarkers={cityMarkers} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DefectTable
                coordinates={filteredCoordinates}
                selectedDefect={selectedDefect}
                handleDefectChange={handleDefectChange}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default App;
