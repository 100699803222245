import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

// 建立 MUI 主題
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Primary 顏色
    },
    secondary: {
      main: '#dc004e', // Secondary 顏色
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {/* 將應用包裹在 ThemeProvider 中，讓所有子組件能使用 MUI 的主題 */}
    <ThemeProvider theme={theme}>
      {/* 使用 CssBaseline 來重置 CSS，保持一致的樣式基礎 */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// 如果需要測量應用效能，使用 reportWebVitals
reportWebVitals();

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
//
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
